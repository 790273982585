
import Vue from "vue";
import store from "@/store";
import CommentsMixin from "@/modules/orders/mixins/comments";
import ClientData from "@/modules/orders/components/ClientData.vue";
import Comments from "@/modules/orders/components/Comments.vue";
import FileUploader from "@/components/form-components/FileUploader.vue";
// import Totals from "@/modules/orders/components/order_types/CERT/Totals.vue";

export default Vue.extend({
  name: "AAIJPreviewOrder",

  mixins: [CommentsMixin],

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    items: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  components: {
    // Totals,
    FileUploader,
    Comments,
    ClientData
  },

  data: () => ({
    selectedField: "" as string,
    loading: false as boolean,
    selectedClient: null as null | SelectComponentInterface,
    selectedCard: null as null | SelectComponentInterface,
    model: {
      documents: [] as Array<any>,
      order: {
        source: "operator",
        client_delegate: "",
        document_type: "new",
        client: { type: "" } as any,
        power_of_attorney: "beneficiary" as string
      } as any
    } as any,
    regions: [
      { text: "Moldova", value: "moldova" },
      { text: "Transnistria", value: "transnistria" }
    ],
    selects: {
      cardTypes: [] as Array<SelectComponentInterface>,
      purpose: [] as Array<SelectComponentInterface>,
      packingCategories: [] as Array<SelectComponentInterface>,
      languages: [] as Array<SelectComponentInterface>,
      client_types: [] as Array<SelectComponentInterface>,
      release_types: [] as Array<SelectComponentInterface>,
      reasons: [] as Array<SelectComponentInterface>
    } as any,
    lang: store.getters["localization/getCurrent"],
    selectedTab: 0,
    totalSum: 0 as number,
    isShowDocuments: true as boolean,
    isShowDocumentsNumber: false as boolean,
    isLoading: false,
    extra_documents: [] as Array<any>,
    extraDocument: null as any,
    breadcrumb: [
      {
        text: "orders.title",
        disabled: false,
        href: "/orders"
      },
      {
        text: "orders.form.header.create",
        disabled: true,
        href: `/orders/edit`
      }
    ],
    defaultCountry: null as any,
    comments: {} as any,
    forceKey: 0 as number
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        // @ts-ignore
        this.model = this.value;
      }
    },
    items: {
      immediate: true,
      handler() {
        // @ts-ignore
        this.comments = this.items;
      }
    }
  },

  computed: {
    goodsSum() {
      return this.model.details.goods.reduce(
        (
          total: number,
          { price, quantity }: { price: number; quantity: number }
        ) => {
          if (price && quantity) {
            return total + price * quantity;
          }
          return total;
        },
        0
      );
    },
    showDocumentTypeComment() {
      return (
        // @ts-ignore
        this.model.order.document_type &&
        ["instead_of_canceled", "retrospectively"].includes(
          this.model.order.document_type?.alias
        ) &&
        // @ts-ignore
        this.model.order.document_type?.alias !== "new"
      );
    },
    showParentDocument() {
      return (
        // @ts-ignore
        this.model.order.document_type &&
        !["retrospectively", "new"].includes(
          this.model.order.document_type?.alias
        )
      );
    },
    documentTypeCommentLabel() {
      // @ts-ignore
      if (this.model.order.document_type?.alias === "retrospectively") {
        return this.$t("orders.form.fields.document_type_comment");
      }
      return this.$t("orders.form.fields.reason_for_cancellation");
    }
  },

  methods: {
    changeKey() {
      this.forceKey = this.forceKey++;
    },
    async submit(): Promise<void> {
      const form = this.$refs.form as Vue;

      if (form.validate()) {
        this.$emit("submit");
      }
    },
    isSelectBeneficiary(): boolean {
      return !this.model.order.client_id;
    }
  }
});
